var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n            <div class=\"header-section\">\r\n                <h1>\r\n                    <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":38}}}) : helper)))
    + " page-icon\"></i>\r\n                    "
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":6,"column":29}}}) : helper)))
    + " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"titleAfter") || (depth0 != null ? lookupProperty(depth0,"titleAfter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"titleAfter","hash":{},"data":data,"loc":{"start":{"line":6,"column":30},"end":{"line":6,"column":46}}}) : helper))) != null ? stack1 : "")
    + "<br><small>"
    + alias4(((helper = (helper = lookupProperty(helpers,"subTitle") || (depth0 != null ? lookupProperty(depth0,"subTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subTitle","hash":{},"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":69}}}) : helper)))
    + " "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"subTitleAfter") || (depth0 != null ? lookupProperty(depth0,"subTitleAfter") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subTitleAfter","hash":{},"data":data,"loc":{"start":{"line":6,"column":70},"end":{"line":6,"column":89}}}) : helper))) != null ? stack1 : "")
    + "</small>\r\n                </h1>\r\n            </div>\r\n        </div>\r\n    </div>";
},"useData":true});