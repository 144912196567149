define([
  'backbone',
],
(Backbone) => Backbone.Marionette.Region.extend({
  showLoader() {
    const view = new Backbone.Marionette.ItemView({
      template() {
        return '<div class="loader"></div>';
      },
    });

    this.show(view);
  },

  showLoaderWithMessage(message) {
    const view = new Backbone.Marionette.ItemView({
      template() {
        return `<div class="loader"></div><h1 class="text-center" data-ui="percentage">${message}</h1>`;
      },
    });

    this.show(view);
  },

  showLoaderPercentage() {
    const view = new Backbone.Marionette.ItemView({
      template() {
        return '<div class="loader"></div><h1 class="text-center" data-ui="percentage">0%</h1>';
      },
      ui: {
        percentage: '[data-ui="percentage"]',
      },
      update(percentage) {
        this.ui.percentage.html(`${percentage}%`);
      },
    });

    this.show(view);

    return view;
  },
}));
