define([
  'application',
  'module',
  'modules/common/views/layout',
  'modules/admin/templates/admin/layouts/main',
  'modules/common/behaviors/autoRegion',
  'modules/admin/views/admin/header',
  'modules/admin/views/admin/sidebar',
  './page',
],
(App, Module, Layout, Template, AutoRegion, HeaderView, SidebarView, PageLayoutView) => Layout.extend({
  template: Template,

  name: 'modules/admin/views/admin/layouts/main',

  regions: {
    header: {
      selector: '#header',
      viewClass: HeaderView,
    },
    page: {
      selector: '#page-content',
      viewClass: PageLayoutView,
    },
    sidebar: {
      selector: '#sidebar',
      viewClass: SidebarView,
    },
    footer: '#footer',
    sidebarAlt: '#sidebar-alt',
  },
}));
