define([
  'application',
  'module',
  'modules/common/views/layout',
  'modules/admin/templates/admin/layouts/page',
  'modules/admin/views/admin/pageHeader',
  'modules/admin/regions/page',
],
(App, Module, Layout, Template, PageHeader, PageRegion) => Layout.extend({
  template: Template,

  name: 'modules/admin/views/admin/layouts/page',

  regionClass: PageRegion,

  regions: {
    header: {
      selector: '#page-header',
      viewClass: PageHeader,
    },
    content: '#page',
  },

  onRender() {
    // on show content, fix height
    this.getRegion('content').on('show', () => {
      const page = $('#page-container');
      const pageContent = $('#page-content');
      const header = $('header');
      const footer = $('#page-content + footer');
      const sidebar = $('#sidebar');
      const sidebarAlt = $('#sidebar-alt');

      const windowH = $(window).height();
      const sidebarH = sidebar.outerHeight();
      const sidebarAltH = sidebarAlt.outerHeight();
      const headerH = header.outerHeight();
      const footerH = footer.outerHeight();

      // If we have a fixed sidebar/header layout or each sidebars’ height < window height
      if (header.hasClass('navbar-fixed-top') || header.hasClass('navbar-fixed-bottom') || ((sidebarH < windowH) && (sidebarAltH < windowH))) {
        if (page.hasClass('footer-fixed')) { // if footer is fixed don't remove its height
          pageContent.css('min-height', `${windowH - headerH}px`);
        } else { // else if footer is static, remove its height
          pageContent.css('min-height', `${windowH - (headerH + footerH)}px`);
        }
      } else { // In any other case set #page-content height the same as biggest sidebar's height
        if (page.hasClass('footer-fixed')) { // if footer is fixed don't remove its height
          pageContent.css('min-height', `${((sidebarH > sidebarAltH) ? sidebarH : sidebarAltH) - headerH}px`);
        } else { // else if footer is static, remove its height
          pageContent.css('min-height', `${((sidebarH > sidebarAltH) ? sidebarH : sidebarAltH) - (headerH + footerH)}px`);
        }
      }
    });
  },

  serializeData() {
    const data = {};
    if (
      App.settings.admin !== undefined
                    && App.settings.admin.breadcrumbs !== undefined
                    && App.settings.admin.breadcrumbs.show_back_button !== undefined
    ) {
      data.breadcrumbsBackButton = App.settings.admin.breadcrumbs.show_back_button;
    } else {
      data.breadcrumbsBackButton = false;
    }
    if (
      App.settings.admin !== undefined
                    && App.settings.admin.breadcrumbs !== undefined
                    && App.settings.admin.breadcrumbs.show_forward_button !== undefined
    ) {
      data.breadcrumbsForwardButton = App.settings.admin.breadcrumbs.show_forward_button;
    } else {
      data.breadcrumbsForwardButton = false;
    }
    return data;
  },
}));
