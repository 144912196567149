define([
  '@storekeeper/sentry',
  'application',
], (SKSentry, Application) => {
  const sentryConfig = {
    dsn: 'https://cac8c57aee5a4ad19a30a382b13ea1dd@sentry.io/1800574',
  };
  if (!!window.storekeeper && 'version' in window.storekeeper) {
    sentryConfig.release = `${window.storekeeper.version}-pos`;
  }
  SKSentry.enableSentry(sentryConfig);

  // Make a public reference
  window.App = Application;

  // Start the application
  Application.start();
});
