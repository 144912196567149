define([
  'module',
  'modules/common/views/layout',
  'modules/admin/templates/login/layout',
],
(Module, Layout, Template) => Layout.extend({
  template: Template,

  name: 'modules/admin/views/login/layout',

  regions: {
    loginContainer: '#login-container',
  },
}));
