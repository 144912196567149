define([
  'application',
  'backbone',
  'modules/admin/templates/admin/menu/menuItem',
  'communicator',
],
(App, Backbone, Template, Communicator) => Backbone.Marionette.CompositeView.extend({
  template: Template,

  tagName: 'li',

  childViewContainer: 'ul',

  events: {
    click: 'onClick',
  },

  onClick() {
    const windowW = window.innerWidth
                    || document.documentElement.clientWidth
                    || document.body.clientWidth;

    // !this.model.get('nodes') -> fix for collapsible menu on mobile apps, so it won`t close
    if (windowW <= 991 && !this.model.get('nodes')) {
      Communicator.mediator.trigger('admin/component/sidebar/handle', 'toggle-sidebar');
    }
  },

  modelEvents: {
    'change nodes': 'nodesChanged',
  },

  className() {
    return this.model.get('className');
  },

  initialize() {
    this.collection = this.model.nodes;
  },

  onRender() {
    if (_.isUndefined(this.collection)) {
      this.$('ul:first').remove();
    }
  },

  nodesChanged() {
    this.collection = this.model.nodes;
    this.render();
  },
}));
