define([
  'backbone',
  'modules/common/subscribers/subscriber',
  'modules/admin/events/redirect/homePage',
  'modules/common/components/historyBreadcrumb',
  'modules/upx/collections/users',
],
(Backbone, Subscriber, RedirectHomeEvent, HistoryBreadcrumb, Users) => {
  const onHome = function () {
    const rightTreeRootShortname = hasTheRightTreeRootShortname();
    if (rightTreeRootShortname) {
      Backbone.history.navigate(
        HistoryBreadcrumb.getIndexFragment(),
        { trigger: true },
      );
    }
  };

  var hasTheRightTreeRootShortname = function () {
    // If there is not user active, there is no shortname to be checked
    const activeUser = Users.findWhere({ active: true });
    if (undefined == activeUser) {
      return true;
    }

    // If the shortname is undefined. you are most probaly a super admin!
    const usersTreeRootShortname = activeUser.getTreeRootShortname();
    if (undefined == usersTreeRootShortname) {
      return true;
    }

    const currentFragment = HistoryBreadcrumb.getCurrentFragment();
    const fragmentRegex = /^auth\/signin\/(\w+)/;

    // If the current fragment is the login one, if not there is no need to kill the current session / user
    if (!fragmentRegex.test(currentFragment)) {
      return true;
    }

    // Check if the shortnames are the same
    const currentTreeRootShortnameExec = fragmentRegex.exec(currentFragment);
    const currentTreeRootShortname = currentTreeRootShortnameExec[1];
    if (currentTreeRootShortname == usersTreeRootShortname) {
      return true;
    }

    // Loggs off the user
    activeUser.logout({
      shortname: currentTreeRootShortname,
    });

    // Reloading is needed because auth/signin is blocked for signed in users
    location.reload();

    // Returns false =D
    return false;
  };

  return Subscriber.extend({
    events: [{
      event: RedirectHomeEvent,
      action: onHome,
    }],
  });
});
