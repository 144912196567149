define([
  'application',
  'backbone',
  'communicator',
  'modules/admin/templates/admin/pageHeader',
  'modules/common/models/pageHeader',
],
(App, Backbone, Communicator, Template, PageHeader) => Backbone.Marionette.ItemView.extend({
  template: Template,

  className: 'content-header',

  model: PageHeader,

  initialize() {
    this.listenTo(this.model, 'change', this.change);
  },

  change() {
    this.render();
  },
}));
