define([
  'backbone',
  'modules/common/controllers/controller',
  'modules/admin/views/admin/layouts/main',
  'modules/common/models/pageHeader',
],
(Backbone, Controller, MainLayout, PageHeader) => Controller.extend({
  mainLayout: MainLayout,

  mainLayoutOptions: {

  },

  before() {
    this.renderLayout([{
      regionName: 'main',
      layoutView: this.mainLayout,
      options: this.mainLayoutOptions,
    }]);

    PageHeader.set({
      titleAfter: '',
      subTitleAfter: '',
    });

    this.getRegionByPath('main.sidebar.menu').currentView.setActive();
  },
}));
